module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/fav-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ddae8361482704fe92d3e8d9fe18cbc7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"src/custom-sw-code.js","workboxConfig":{"importWorkboxFrom":"local","dontCacheBustURLsMatching":{},"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":2}},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":2}}],"skipWaiting":true,"clientsClaim":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PNGNVTW","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-cloudofertas/gatsby-browser.js'),
      options: {"plugins":[],"env":{"dev":{"siteId":"329bf3ba-dc28-4385-b127-59218ce54465","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImVkOTk4MmY0LTQwMjQtNGRjMi1iYWYwLTVlYzY5NWI3YzhiZSIsInVzZXIiOiIzOTVhOGJiZC05ODVlLTRlY2EtOWU5ZS04NGRiOTkzNGVlZDAiLCJzaXRlIjoiMzI5YmYzYmEtZGMyOC00Mzg1LWIxMjctNTkyMThjZTU0NDY1IiwiaWF0IjoxNjc3NjkwNjU5fQ.khAiiOfoNKLSHPN9tbMiHc0tzlST2Rt5TZ6gujFg7Hg"},"prd":{"siteId":"329bf3ba-dc28-4385-b127-59218ce54465","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImVkOTk4MmY0LTQwMjQtNGRjMi1iYWYwLTVlYzY5NWI3YzhiZSIsInVzZXIiOiIzOTVhOGJiZC05ODVlLTRlY2EtOWU5ZS04NGRiOTkzNGVlZDAiLCJzaXRlIjoiMzI5YmYzYmEtZGMyOC00Mzg1LWIxMjctNTkyMThjZTU0NDY1IiwiaWF0IjoxNjc3NjkwNjU5fQ.khAiiOfoNKLSHPN9tbMiHc0tzlST2Rt5TZ6gujFg7Hg"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src1942239811/src/src/intl","languages":["pt-BR","en"],"defaultLanguage":"pt-BR","redirect":true,"redirectDefaultLanguageToRoot":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
